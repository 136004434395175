import React from "react"
import Layout from '../components/layout'
import { Hero } from '../components/Hero'

const Profile = () => {
  return (
    <Layout>
      <Hero tagline="Hi my name is"></Hero>
    </Layout>
  )
}

export default Profile
